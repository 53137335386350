import React from 'react'
import { useEffect } from 'react'
import { navigate } from 'gatsby'

export default () => {
  useEffect(() => {
    navigate('/')
  }, [])
  return (
    <div
      style={{ backgroundColor: 'black', width: '100vw', height: '100vh' }}
    />
  )
}
